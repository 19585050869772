import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  isLoadingUserData: false,
  userDataError: null,
  token: null,
  companyId: null,
  siteId: null,
  username: null,
  userInfo: null,
  companyInfo: null,
  siteInfo: null,
  login: () => {},
  logout: () => {},
  updateExpiration: () => {},
});
